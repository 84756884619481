export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'APP_DASHBOARD',
    action: 'read',
  },
  {
    title: 'Site Genel',
    route: null,
    icon: 'GlobeIcon',
    children: [
      {
        title: 'Site Menü',
        route: 'SiteMenus',
        resource: 'ADMIN_SITE_MENU',
        action: 'read',
      },
      {
        title: 'Carousel',
        route: 'Carousel',
        resource: 'ADMIN_CAROUSEL',
        action: 'read',
      },
      {
        title: 'Sabit İçerikler',
        route: 'Contents',
        resource: 'ADMIN_CONTENTS',
        action: 'read',
      },
    ],
  },
  {
    title: 'Kurumsal',
    route: null,
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Hakkımızda',
        route: 'About',
        resource: 'ADMIN_ABOUT',
        action: 'read',
      },
      {
        title: 'Genel Bakış',
        route: 'Overview',
        resource: 'ADMIN_OVERVIEW',
        action: 'read',
      },
      {
        title: 'Haberler',
        route: 'News',
        resource: 'ADMIN_NEWS',
        action: 'read',
      },
      {
        title: 'Kalite Belgeleri',
        route: 'Qualities',
        resource: 'ADMIN_QUALITIES',
        action: 'read',
      },
      {
        title: 'Kullanım Alanları',
        route: '#',
        resource: 'ADMIN_USAGE_AREAS',
        action: 'read',
      },
      {
        title: 'İletişim',
        route: 'Contacts',
        resource: 'ADMIN_CONTACT',
        action: 'read',
      },
      {
        title: 'Whatsapp Yapılandırma',
        route: '#',
        resource: 'ADMIN_WHATSAPP',
        action: 'read',
      },
    ],
  },
  {
    title: 'Galeri',
    route: null,
    icon: 'CameraIcon',
    children: [
      {
        title: 'Fotoğraf Galerisi',
        route: '#',
        resource: 'ADMIN_PHOTO_GALLERY',
        action: 'read',
      },
      {
        title: 'Video Galerisi',
        route: '#',
        resource: 'ADMIN_VIDEO_GALLERY',
        action: 'read',
      },
    ],
  },
  {
    title: 'Ürünler',
    route: null,
    icon: 'BoxIcon',
    children: [
      {
        title: 'Ürün Listesi',
        route: '#',
        resource: 'ADMIN_PRODUCTS',
        action: 'read',
      },
      {
        title: 'Ürün Grupları',
        route: 'ProductGroups',
        resource: 'ADMIN_PRODUCTS',
        action: 'read',
      },
      {
        title: 'Fiyat Listesi',
        route: 'ProductGroups',
        resource: 'ADMIN_PRODUCTS',
        action: 'read',
      },
    ],
  },
  {
    title: 'Siparişler',
    route: null,
    icon: 'ShoppingCartIcon',
    children: [
      {
        title: 'Sipariş Listesi',
        route: '#',
        resource: 'ADMIN_ORDERS',
        action: 'read',
      },
    ],
  },
  {
    title: 'Bayiler',
    route: null,
    icon: 'UserIcon',
    children: [
      {
        title: 'Bayi Oluştur',
        route: '#',
        resource: 'ADMIN_CUSTOMERS',
        action: 'read',
      },
      {
        title: 'Bayi Listesi',
        route: '#',
        resource: 'ADMIN_CUSTOMERS',
        action: 'read',
      },
    ],
  },
  {
    title: 'Modüller',
    route: null,
    icon: 'CodesandboxIcon',
    children: [
      {
        title: 'Sosyal Medya',
        route: 'Socialmedia',
        resource: 'ADMIN_MODULES',
        action: 'read',
      },
    ],
  },
  {
    title: 'Dil Çeviri',
    route: null,
    icon: 'FlagIcon',
    children: [
      {
        title: 'Çeviri Listesi',
        route: 'Translate',
        resource: 'ADMIN_TRANSLATE',
        action: 'read',
      },
    ],
  },
  {
    title: 'Tanımlamalar',
    route: null,
    icon: 'DatabaseIcon',
    children: [
      {
        title: 'Başvuru Durumları',
        route: '#',
        resource: 'ADMIN_DEFINES',
        action: 'read',
      },
      {
        title: 'Para Birimleri',
        route: '#',
        resource: 'ADMIN_DEFINES',
        action: 'read',
      },
      {
        title: 'Vergi Oranları',
        route: '#',
        resource: 'ADMIN_DEFINES',
        action: 'read',
      },
      {
        title: 'Birim Tipleri',
        route: '#',
        resource: 'ADMIN_DEFINES',
        action: 'read',
      },
      {
        title: 'Bayi Tipleri',
        route: '#',
        resource: 'ADMIN_DEFINES',
        action: 'read',
      },
      {
        title: 'Nakliye Tipleri',
        route: '#',
        resource: 'ADMIN_DEFINES',
        action: 'read',
      },
      {
        title: 'Sipariş Tipleri',
        route: '#',
        resource: 'ADMIN_DEFINES',
        action: 'read',
      },
      {
        title: 'Sipariş Durumları',
        route: '#',
        resource: 'ADMIN_DEFINES',
        action: 'read',
      },
      {
        title: 'Temas Tipleri',
        route: '#',
        resource: 'ADMIN_DEFINES',
        action: 'read',
      },
      {
        title: 'İlgili Kişi Tipleri',
        route: '#',
        resource: 'ADMIN_DEFINES',
        action: 'read',
      },
      {
        title: 'Ülkeler',
        route: '#',
        resource: 'ADMIN_DEFINES',
        action: 'read',
      },
      {
        title: 'Şehirler',
        route: '#',
        resource: 'ADMIN_DEFINES',
        action: 'read',
      },
    ],
  },
  {
    title: 'Kullanıcılar',
    route: 'Users',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Kullanıcılar',
        route: 'Users',
        resource: 'ADMIN_USERS',
        action: 'read',
      },
      {
        title: 'Kullanıcı Yetkileri',
        route: 'UserTypes',
        resource: 'ADMIN_USER_TYPES',
        action: 'read',
      },
    ],
  },
  {
    title: 'Ayarlar',
    route: null,
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Genel Yapılandırma',
        route: 'Configs',
        resource: 'ADMIN_CONFIG',
        action: 'read',
      },
      {
        title: 'SEO Yapılandırma',
        route: 'Components',
        resource: 'ADMIN_CONFIG',
        action: 'read',
      },
      {
        title: 'SMTP',
        route: 'Smtp',
        resource: 'ADMIN_SMTP',
        action: 'read',
      },
    ],
  },
]
